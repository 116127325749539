import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setActiveModal, setActivePanel } from "../store/mainReducer"

import ServerConnect from '../service';

const Main = () => {

    const dispatch = useDispatch()

    return (
        <div className="container main-page page">
            <div className="launch-card">
                <img className="launch-card__pic" width="216" loading="lazy" decoding="async" src="assets/images/prizes.png" alt="dinos" />
                <h1 className="caption caption_size_middle launch-card__caption">Какой ты Завр?</h1>
                <p className="text launch-card__text">Проходи тест и&nbsp;участвуй в&nbsp;розыгрыше игровой приставки, умной колонки, беспроводных наушников и&nbsp;других крутых призов!</p>
                <button
                    className="button button_color_yellow launch-card__button button_size_small"
                    onClick={()=>dispatch(setActivePanel('start'))}
                >Пройти тест</button>
            </div>
            <div className="main-page__buttons">
                <button
                    className="button button_color_yellow button_size_big"
                    onClick={()=>{dispatch(setActiveModal('team'))}}
                >
                    Собрать<br/>команду завров
                    {/* <i>Старт 5 сентября</i> */}
                </button>
                <button
                    className="button button_color_yellow button_size_big"
                    onClick={()=>{dispatch(setActiveModal('contest'))}}
                >Конкурс <br/>мемов
                    {/* <i>Старт 29 августа</i> */}
                </button>
            </div>
            <div className="info-block main-page__info-block">
                Нажми на <b>Завра</b> и добавься <br/>к нему в друзья:
            </div>
            <div className="dino-list main-page__dino-list">
                <a href="https://vk.com/id815646203" className="dino-list__item" target="_blank">
                    <img src="assets/images/dino1.png" width="100" alt="" loading="lazy" decoding="async" />
                </a>
                <a href="https://vk.com/id815646987" className="dino-list__item" target="_blank">
                    <img src="assets/images/dino2.png" width="100" alt="" loading="lazy" decoding="async" />
                </a>
                <a href="https://vk.com/id815646622" className="dino-list__item" target="_blank">
                    <img src="assets/images/dino3.png" width="115" alt="" loading="lazy" decoding="async" />
                </a>
            </div>
            <div className="main-page__bottom">
                <button
                    className="info-button"
                    onClick={()=>{dispatch(setActiveModal('about-contest'))}}
                >
                    <img src="assets/images/info-icon.svg" alt="" loading="lazy" decoding="async" />
                </button>
                <a
                    href="https://5ka.onelink.me/ml1j/9i7f4ecv"
                    className="button button_color_red button_size_small main-page__bottom-button"
                    target="_blank"
                >Еще больше призов в&nbsp;игре приложении!</a>
            </div>
        </div>
    );
}

export default Main;

