import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { setActivePanel } from "../store/mainReducer"

const Loading = () => {
    const dispatch = useDispatch()

    useEffect(() => {

        const loading = setTimeout(() => {
            dispatch(setActivePanel('main'))
        }, 2500);
        return () => clearTimeout(loading);

    }, []);

    return (
        <div className="container loading-page">
            <img className="loading-page__company-logo" width="192" height="62" src="assets/images/logo-black.svg" loading="lazy" decoding="async" alt="5-logo" />
            <img className="loading-page__main-pic" width="365" src="assets/images/main-pic.png" loading="lazy" decoding="async" alt="dinos" />
            <div className="loading-wrapper">
                <div className="loading-block loading-page__loading-block">
                    <div className="loading-block__bar"></div>
                </div>
                <p className="loading-wrapper__text">Загр-р-р-р-р-узка…</p>
            </div>
        </div>
    );
}


export default Loading;

