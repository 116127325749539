import React,{useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Start from './panels/Start';
import Test from './panels/Test';
import Main from './panels/Main';
import Loading from './panels/Loading';
import Results from './panels/Results';
import Share from './panels/Share';
import Done from './panels/Done';
import Overlay from './panels/Overlay';
import bridge from '@vkontakte/vk-bridge';
import ServerConnect from './service';

import { setActivePanel, setActiveModal, setLaunchParams, updateHistory, setUserPhoto, setPlatform } from "./store/mainReducer"

const App = () => {
  const dispatch = useDispatch()
  const activePanel = useSelector(state => state.main.activePanel)
  const activeModal = useSelector(state => state.main.activeModal)
  const history = useSelector(state => state.main.history)
  const platform = useSelector(state => state.main.platform)

  useEffect(() => {

    async function fetchData() {
      const launch_params = await bridge.send("VKWebAppGetLaunchParams");
      console.log(launch_params)
      if (launch_params) {
        return launch_params;
      }
    }

    fetchData().then(data => {
      dispatch(setLaunchParams(data))
      dispatch(setPlatform(data.vk_platform))
      bridge.send('VKWebAppGetUserInfo', {
        user_id: data.vk_user_id
      })
      .then((data) => {
        if (data.id) {
          dispatch(setUserPhoto(data.photo_max_orig))
        }
      })
      .catch((error) => {
        console.log(error);
      });

      ServerConnect.sendGet('/game_status', {...data}).then(data=>{

      }).catch(error=>{

      })
    })


  }, [])

  const goBack = ()=> {
    dispatch(setActivePanel(history[history.length-2]))
    dispatch(updateHistory(history.slice(0, -1)))
  }

  const ContestModal = ()=> {
    return (
      <div className="modal contest-modal" onClick={(e)=>{e.stopPropagation();}}>
        <p className="contest-modal__caption caption caption_size_middle">Конкурс мемов</p>
        <p className="text modal__text">Придумай мем и&nbsp;выиграй сертификат в&nbsp;магазин электроники!<br/><i></i></p>
        <img className="contest-modal__image" width="183" height="183" src="assets/images/meme-dino.png" alt="" />
        <a href="https://vk.com/wall-19098821_1134887" className="contest-modal__button button button_size_small button_color_yellow" target="_blank">Участвовать в конкурсе</a>
        <button className="modal__close" onClick={()=>dispatch(setActiveModal(null))}>
          <img src="assets/images/close-icon.svg" alt="" />
        </button>
      </div>
      // <div className="modal contest-modal" onClick={(e)=>{e.stopPropagation();}}>
      //   <p className="contest-modal__caption caption caption_size_middle">Конкурс мемов</p>
      //   <p className="text modal__text">Конкурс начнётся 29&nbsp;августа. <br/>Пр-р-р-риходи, мы&nbsp;будем ждать тебя!</p>
      //   <button className="modal__close" onClick={()=>dispatch(setActiveModal(null))}>
      //     <img src="assets/images/close-icon.svg" alt="" />
      //   </button>
      // </div>
    )
  }

  const AboutContestModal = ()=> {
    return (
      <div className="modal about-contest-modal" onClick={(e)=>{e.stopPropagation();}}>
        <p className="about-contest-modal__caption caption caption_size_middle">Больше о конкурсе</p>
        <div className="about-contest-modal__buttons">
          <a href="https://docs.google.com/document/d/1We6rmrEEtIv3tVVUEkRL0LOrU_SA2ixK92xKeC7U88I/edit?usp=sharing" className="about-contest-modal__button button button_size_small button_color_yellow" target="_blank">Правила конкурсов</a>
          <a href="https://5ka.onelink.me/ml1j/9i7f4ecv" className="about-contest-modal__button button button_size_small button_color_yellow" target="_blank">Перейти в приложение</a>
          <a className="about-contest-modal__button button button_size_small button_color_yellow" href="https://docs.google.com/spreadsheets/d/1gJRbr_7ecJlSAR5r2HHdi3t7Evor8BgzPImc1HgWsl4/edit?usp=sharing " target="_blank">Победители</a>
          <a className="about-contest-modal__button button button_size_small button_color_red" href="https://vk.com/pyaterochka_shop" target="_blank">Подписаться на Пятёрочку</a>
        </div>
        <button className="modal__close" onClick={()=>dispatch(setActiveModal(null))}>
          <img src="assets/images/close-icon.svg" alt="" />
        </button>
      </div>
    )
  }

  const TeamModal = ()=> {
    return (
      <div className="modal contest-modal" onClick={(e)=>{e.stopPropagation();}}>
        <p className="contest-modal__caption caption caption_size_middle">Собрать команду завров</p>
        <p className="text modal__text">Ответь на вопросы, собери всех завров и выиграй призы!</p>
        <a href="https://vk.com/wall-19098821_1139725" className="contest-modal__button button button_size_small button_color_yellow" target="_blank">Участвовать</a>
        <button className="modal__close" onClick={()=>dispatch(setActiveModal(null))}>
          <img src="assets/images/close-icon.svg" alt="" />
        </button>
      </div>
    )
  }

  let content = []
  if (activePanel === 'loading') {
    content.push(<Loading goBack={goBack} key='loading'/>)
  } else if (activePanel === 'main') {
    content.push(<Main goBack={goBack} key='main'/>)
  } else if (activePanel === 'start') {
    content.push(<Start goBack={goBack} key='start'/>)
  } else if (activePanel === 'results') {
    content.push(<Results goBack={goBack} key='results'/>)
  } else if (activePanel === 'test') {
    content.push(<Test goBack={goBack} key='test'/>)
  } else if (activePanel === 'share') {
    content.push(<Share goBack={goBack} key='share'/>)
  } else if (activePanel === 'done') {
    content.push(<Done goBack={goBack} key='done'/>)
  }

  return (
    <div className={`app ${platform}`}>
      <>
        {content}
        <Overlay>
          {
            activeModal === 'contest' ? <ContestModal /> :
            activeModal === 'team' ? <TeamModal /> :
            activeModal === 'about-contest' && <AboutContestModal />
          }
        </Overlay>
      </>
    </div>
  );
}
export default App;
