import {createSlice} from "@reduxjs/toolkit";

const mainSlice = createSlice({
    name: 'main',
    initialState: {
        platform: null,
        activePanel: 'loading',
        userPhoto: null,
        launch_params: {
        },
        history: ['loading'],
        isLoading: false,
        activeModal: null,
        resultPic: null,
        dinoType: 'O',
        dinosInfo: [
            {type: 'A', id: 8, name: 'Паразауролоф', text: 'Ты — очень общительный Завр. У тебя уже куча друзей, но тебе всё мало!', share: 'Я — Паразауролоф, очень общительный Завр.'},
            {type: 'B', id: 16, name: 'Трицератопс', text: 'Ты — серьёзный Завр! Тебя лучше не злить.', share: 'Я — Трицератопс, добрый Завр.'},
            {type: 'C', id: 9, name: 'Протоцератопс', text: 'Ты — семейный Завр. Больше всего ты любишь проводить время с близкими.', share: 'Я — Протоцератопс, семейный Завр.'},
            {type: 'D', id: 13, name: 'Стигимолох', text: 'Ты — просто машина! Даже Тиранозавр тебя не догонит.', share: 'Я — Стигимолох, просто машина!'},
            {type: 'E', id: 1, name: 'Апатозавр', text: 'Ты — очень высокий Завр!  Поможешь достать листик воооооон с того дерева?', share: 'Я — Апатозавр, оооочень высокий Завр.'},
            {type: 'F', id: 6, name: 'Игуанодон', text: 'Ты — мечтательный Завр! Ты любишь гулять по лесу и думать о чём-то своём.', share: 'Я — Игуанодон, мечтательный Завр.'},
            {type: 'G', id: 12, name: 'Стегозавр', text: 'Твоих шипов все боятся, но в душе ты — добряк.', share: 'Я — Стегозавр, очень фотогеничный Завр.'},
            {type: 'H', id: 14, name: 'Стиракозавр', text: 'Ты — очень эффектный Завр! Это видно сразу.', share: 'Я — Стиракозавр, эффектный Завр.'},
            {type: 'I', id: 2, name: 'Велоцираптор', text: 'Ты — очень быстрый завр. Ты всегда выигрываешь в догонялки!', share: 'Я — Велоцираптор, очень быстрый Завр.'},
            {type: 'J', id: 15, name: 'Тираннозавр', text: 'Без лишних слов: ты — легенда! Можно автограф?', share: 'Я — Тираннозавр, и я легенда.'},
            {type: 'K', id: 7, name: 'Мозазавр', text: 'Ты — первый пират на Земле! Просто гроза морей!', share: 'Я — Мозазавр, гроза морей.'},
            {type: 'L', id: 11, name: 'Спинозавр', text: 'Ты — пляжный Завр! Больше всего ты любишь проводить время у водички. ', share: 'Я — Спинозавр, пляжный Завр.'},
            {type: 'M', id: 5, name: 'Дилофозавр', text: 'Ты — очень стильный завр. Все просто в шоке от твоего гребня!', share: 'Я — Дилофозавр, очень стильный Завр.'},
            {type: 'N', id: 4, name: 'Детеныш тираннозавра', text: 'Тебя ждёт большое будущее!', share: 'Я — детёныш тираннозавра, меня ждёт большое будущее.'},
            {type: 'O', id: 10, name: 'Птеранодон', text: 'Ты — Завр-путешественник и облетел весь мир. Повезло же тебе!', share: 'Я — Птеранодон, я облетел весь мир.'},
            {type: 'P', id: 3, name: 'Галимимус', text: 'Ты всегда в форме, потому что никогда не пропускаешь утреннюю пробежку!', share: 'Я — Галимимус, спортивный Завр.'},
        ],
        test: [
            {
                id: 1,
                question: 'Давай начнём с суперспособностей. Какой из них ты бы хотел обладать?',
                answers: [
                    {
                        title: 'Молниеносно бегать',
                        types: ['A', 'D', 'I', 'N', 'P']
                    }, {
                        title: 'Обладать мощной силой',
                        types: ['B', 'C', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'O']
                    }, {
                        title: 'Высоко летать',
                        types: ['O']
                    }
                ]
            },
            {
                id: 2,
                question: 'Что ты готов есть каждый день?',
                answers: [
                    {
                        title: 'Мороженое',
                        types: ['A','B','C','D','E','F','G','H','P']
                    }, {
                        title: 'Арбузы',
                        types: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']
                    }, {
                        title: 'Котлетки с пюрешкой',
                        types: ['I', 'J', 'K', 'L', 'M', 'N', 'O']
                    }
                ]
            },
            {
                id: 3,
                question: 'Каких завров любишь больше всего?',
                answers: [
                    {
                        title: 'С острыми зубами',
                        types: ['A','B','C','D','G','H','L','M','O']
                    }, {
                        title: 'С рогами',
                        types: ['E','F','I','J','K','N','P']
                    },{
                        title: 'С длинным хвостом',
                        types: ['E','F','I','J','K','N','P']
                    }
                ]
            },
            {
                id: 4,
                question: 'Какой ты?',
                answers: [
                    {
                        title: 'Спокойный',
                        types: ['A','B','E','G','H']
                    }, {
                        title: 'Активный',
                        types: [
                            'C','I','J','K','L','O','P'
                        ]
                    },  {
                        title: 'Весёлый',
                        types: [
                            'D','F','M','N'
                        ]
                    }
                ]
            },
            {
                id: 5,
                question: 'Где ты мечтал бы жить?',
                answers: [
                    {
                        title: 'Высоко в горах',
                        types: [
                            'A','C','I','O'
                        ]
                    }, {
                        title: 'В густом лесу',
                        types: [
                            'B','D','E','H','J','N','P'
                        ]
                    },  {
                        title: 'На тропическом острове',
                        types: [
                            'F','G','K','L','M'
                        ]
                    }
                ]
            }
        ]
    },
    reducers: {
        setLaunchParams(state, action) {
            state.launch_params = action.payload
        },
        setActivePanel(state, action) {
            state.activePanel = action.payload
            state.history = [...state.history, action.payload]
            // window.history.pushState({panel: state.activePanel}, 'start')
        },
        setActiveModal(state, action) {
            state.activeModal = action.payload
        },
        setMessageModalTitle(state, action){
            state.messageModalTitle = action.payload
        },
        setMessageModalText(state, action){
            state.messageModalText = action.payload
        },
        setIsLoading(state, action){
            state.isLoading = action.payload
        },
        updateHistory(state, action){
            state.history = action.payload
        },
        setUserPhoto(state, action){
            state.userPhoto = action.payload
        },
        setResultPic(state, action){
            state.resultPic = action.payload
        },
        setDinoType(state, action){
            state.dinoType = action.payload
        },
        setPlatform(state, action){
            state.platform = action.payload
        },
    }
})

export default mainSlice.reducer
export const {
    setActivePanel,
    setLaunchParams,
    setActiveModal,
    setIsLoading,
    updateHistory,
    setUserPhoto,
    setResultPic,
    setDinoType,
    setPlatform
} = mainSlice.actions
