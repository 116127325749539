import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setActiveModal, setActivePanel, setPhotoUrl, setFileObject } from "../store/mainReducer"

import ServerConnect from '../service';

const Start = ({goBack}) => {
    const dispatch = useDispatch()
    const launch_params = useSelector(state => state.main.launch_params)

    return (
        <div className={`container page start-page`}>
            <button className="page__back-button" onClick={()=>dispatch(setActivePanel('main'))}>
                <img src="assets/images/arrow-icon.svg" alt=""/>
            </button>
            <h1 className="caption caption_size_big start-page__caption">Узнай какой ты&nbsp;Завр!</h1>
            <img src="assets/images/pattern1.png" loading="lazy" decoding="async" class="start-page__shape1" width="96" alt="" />
            <div className="start-card">
                <img src="assets/images/pattern2.png" loading="lazy" decoding="async" class="start-page__shape2" width="73" alt="" />
                <p className="text start-card__text">Пройди короткий тест и&nbsp;узнай, на&nbsp;кого из&nbsp;Завров ты&nbsp;похож больше всего.<br/><br/>Репости результаты к&nbsp;себе на&nbsp;стену и&nbsp;участвуй розыгрыше классных призов! Итоги конкурса опубликуем <a href="https://docs.google.com/spreadsheets/d/1gJRbr_7ecJlSAR5r2HHdi3t7Evor8BgzPImc1HgWsl4/edit#gid=0" target="_blank">здесь</a> 26 сентября.</p>
                <button
                    className="button button_size_big button_color_red start-page__button"
                    onClick={()=>{
                        dispatch(setActivePanel('test'))
                        ServerConnect.sendPost('statistics', {name: 'test_started'}, {...launch_params}).then(data=>{
                            console.log(data)
                        }).catch(error=>{
                            console.log(error)
                        })
                    }}
                >Начать тест</button>
                <img src="assets/images/pattern3.png" class="start-page__shape3" loading="lazy" decoding="async" width="90" alt="" />
            </div>
            <img className="start-page__dino" width="254" src="assets/images/start-screen-dino.png" loading="lazy" decoding="async" alt="" />
        </div>
    );
}

export default Start;

