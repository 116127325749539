import React from 'react';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {saveAs} from "file-saver";
import { setActivePanel, setActiveModal} from "../store/mainReducer"
import ServerConnect from '../service';
import bridge from '@vkontakte/vk-bridge';

const Results = () => {
    const dispatch = useDispatch()
    const launch_params = useSelector(state => state.main.launch_params)
    const dinoType = useSelector(state => state.main.dinoType)
    const userPhoto = useSelector(state => state.main.userPhoto)
    const resultPic = useSelector(state => state.main.resultPic)
    const dinosInfo = useSelector(state => state.main.dinosInfo)
    const dinoInfo = dinosInfo.find(dino=>dino.type === dinoType)
    const dinoName = dinoInfo.name
    const dinoText = dinoInfo.text
    const dinoShare = dinoInfo.share

    const share = ()=> {
        let shareImageUrl = resultPic

        let params = {
            "owner_id": launch_params.vk_user_id,
            "message": `${dinoShare} А ты? Проходи тест, делись результатами и выигрывай крутые призы!`,
            "attachments": "https://vk.com/app51720790",
            "link_button": 'open_url',
            "link_image": shareImageUrl
        }

        bridge.send("VKWebAppShowWallPostBox", params)
        .then(data=>{
            dispatch(setActivePanel('share'))
            ServerConnect.sendPost('statistics', {name: `repost_${launch_params.vk_user_id}`}, {...launch_params}).then(data=>{
                console.log(data)
            }).catch(error=>{
                console.log(error)
            })
        })
        .catch(err=>console.log(err))

    }

    return (
        <div className={`container page results-page`}>
            <button className="page__back-button" onClick={()=>dispatch(setActivePanel('main'))}>
                <img src="assets/images/arrow-icon.svg" loading="lazy" decoding="async" alt=""/>
            </button>
            <div className="result-pic">
                <div className="result-pic__user-wrapper">
                    <img className="result-pic__user" width="210" height="210" src={resultPic} loading="lazy" decoding="async" alt="" />
                </div>
                {/* <img className="result-pic__dino" width="127" src={`assets/images/dino-types/dino-${dinoType}.png`} loading="lazy" decoding="async" alt="" /> */}
                <img src="assets/images/pattern1.png" className="result-pic__shape1" width="120" loading="lazy" decoding="async" alt="" />
                <img src="assets/images/pattern2.png" className="result-pic__shape2" width="100" loading="lazy" decoding="async" alt="" />
            </div>
            <div className="results-page__info">
                <h1 className="caption caption_size_big results-page__caption">{dinoName}</h1>
                <p className="text results-page__text">{dinoText}</p>
            </div>
            <div className="result-card result-page__result-card">
                <h2 className="caption caption_size_small result-card__caption">Расскажи об этом <br/>своим друзьям!</h2>
                <p className="text result-card__text">Вот это да, ты не&nbsp;кусаешься? Тогда поделись результатом, стань участником розыгрыша и&nbsp;получи свою аватарку от&nbsp;Завров!</p>
                <button
                    className="button button_size_small button_color_yellow result-card__button"
                    onClick={share}
                >
                    Поделиться
                </button>
            </div>
            <button className="repeat-button" onClick={()=>{
                dispatch(setActivePanel('test'))
                ServerConnect.sendPost('statistics', {name: `repeat_${launch_params.vk_user_id}`}, {...launch_params}).then(data=>{
                    console.log(data)
                }).catch(error=>{
                    console.log(error)
                })
            }}>Пройти еще раз</button>
        </div>
    );
}

export default Results;

