import React from 'react';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {saveAs} from "file-saver";
import { setActivePanel, setActiveModal} from "../store/mainReducer"
import ServerConnect from '../service';
import bridge from '@vkontakte/vk-bridge';

const Share = ({goBack}) => {
    const dispatch = useDispatch()
    const resultPic = useSelector(state => state.main.resultPic)
    const launch_params = useSelector(state => state.main.launch_params)
    const platform = useSelector(state => state.main.platform)

    function downloadImage(imageSrc) {
        const imageUrl = imageSrc
        const link = document.createElement('a')
        link.href = imageUrl
        link.download = 'Завр'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    return (
        <div className={`container page share-page`}>
            <button className="page__back-button" onClick={()=>dispatch(setActivePanel('main'))}>
                <img src="assets/images/arrow-icon.svg" alt=""/>
            </button>
            <div className="share-card share-page__share-card">
                <img className="share-page__pic" width="255" src="assets/images/prizes.png" alt="dinos" />
                <h1 className="caption caption_size_middle share-page__caption">Кр-р-р-уто! Хочешь получить главный приз? </h1>
                <p className="text share-page__text">Ставь свою аватарку с&nbsp;Завром, укажи под фото хештег #ЗаврЗацени и&nbsp;получи шанс выиграть главный приз — игровую приставку!</p>
            </div>
            <div className="share-page__bottom">
                <img src={resultPic} alt="" loading="lazy" decoding="async" className="share-page__user" />
                {
                    platform === 'desktop_web' ?
                    <button onClick={()=>{
                        saveAs(resultPic, "Завр")
                        ServerConnect.sendPost('statistics', {name: `download_${launch_params.vk_user_id}`}, {...launch_params}).then(data=>{
                            console.log(data)
                        }).catch(error=>{
                            console.log(error)
                        })
                    }} className="share-page__download">
                        <img src="assets/images/download-icon.svg" alt="" loading="lazy" decoding="async" />
                    </button> :
                    // <a href={resultPic} className="share-page__download" target="_blank" download>
                    //     <img src="assets/images/download-icon.svg" alt="" loading="lazy" decoding="async" />
                    // </a>
                    <button onClick={()=>{
                        downloadImage(resultPic)
                        ServerConnect.sendPost('statistics', {name: `download_${launch_params.vk_user_id}`}, {...launch_params}).then(data=>{
                            console.log(data)
                        }).catch(error=>{
                            console.log(error)
                        })
                    }} className="share-page__download">
                        <img src="assets/images/download-icon.svg" alt="" loading="lazy" decoding="async" />
                    </button>
                }
            </div>
            <button className="repeat-button" onClick={()=>dispatch(setActivePanel('test'))}>Пройти еще раз</button>
        </div>
    );
}

export default Share;

