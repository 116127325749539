import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setActiveModal, setActivePanel, setPhotoUrl, setFileObject, setDinoType, setResultPic } from "../store/mainReducer"

import ServerConnect from '../service';
import bridge from '@vkontakte/vk-bridge';

const Test = ({goBack}) => {
    const dispatch = useDispatch()
    const launch_params = useSelector(state => state.main.launch_params)
    const test = useSelector(state => state.main.test)
    const [questionIndex, setQuestionIndex] = useState(0)
    const currentQuestion = test[questionIndex]
    const userPhoto = useSelector(state => state.main.userPhoto)

    const [dinoTypes, setDinoTypes] = useState(
        [
            {type: 'A', count: 0, id: 8},
            {type: 'B', count: 0, id: 16},
            {type: 'C', count: 0, id: 9},
            {type: 'D', count: 0, id: 13},
            {type: 'E', count: 0, id: 1},
            {type: 'F', count: 0, id: 6},
            {type: 'G', count: 0, id: 12},
            {type: 'H', count: 0, id: 14},
            {type: 'I', count: 0, id: 2},
            {type: 'J', count: 0, id: 15},
            {type: 'K', count: 0, id: 7},
            {type: 'L', count: 0, id: 11},
            {type: 'M', count: 0, id: 5},
            {type: 'N', count: 0, id: 4},
            {type: 'O', count: 0, id: 10},
            {type: 'P', count: 0, id: 3}
        ]
    )

    const toResults = ()=> {
        const dinoTypesFinal = dinoTypes.filter(object=>object.count > 2)
        const dino = dinoTypesFinal[Math.floor(Math.random()*dinoTypesFinal.length)]
        dispatch(setDinoType(dino.type))

        bridge.send('VKWebAppCreateHash', {payload: JSON.stringify(launch_params.vk_user_id)} )
        .then((data) => {
            if (data.sign) {
                ServerConnect.sendPost('avatar', {
                    image_url: userPhoto,
                    template_number: dino.id,
                    user_id: launch_params.vk_user_id
                }, {...launch_params, payload: data.payload, ts: data.ts, hash_sign: data.sign}).then(data=>{
                    dispatch(setResultPic(`${ServerConnect.api_url}${data}`));
                    dispatch(setActivePanel('results'))
                }).catch(error=>{
                    console.log(error)
                })
            }
        }).catch(error=>console.log(error))

        ServerConnect.sendPost('statistics', {name: 'test_done'}, {...launch_params}).then(data=>{
            console.log(data)
        }).catch(error=>{
            console.log(error)
        })

        ServerConnect.sendPost('statistics', {name: `finished_${launch_params.vk_user_id}`}, {...launch_params}).then(data=>{
            console.log(data)
        }).catch(error=>{
            console.log(error)
        })
    }

    const toNext = (types)=>{

        const dinoTypesUpdated = [...dinoTypes]
        types.map((type)=>{
            dinoTypesUpdated.find(object => {
                if(object.type == type){
                    object.count++
                }
            })
        })

        setDinoTypes([...dinoTypesUpdated])

        if(questionIndex+1 < test.length){
            setQuestionIndex(questionIndex+1)
        } else {
            toResults()
        }
    }

    return (
        <div className={`container page test-page`}>
            <button className="page__back-button" onClick={()=>dispatch(setActivePanel('start'))}>
                <img src="assets/images/arrow-icon.svg" loading="lazy" decoding="async" alt=""/>
            </button>
            <div className="test-pic test-page__test-pic">
                <img src={`assets/images/test-pic${questionIndex+1}.png`} width="331" loading="lazy" decoding="async" alt="" />
                <p className="test-pic__text">
                    Вопрос {questionIndex+1} из 5
                </p>
            </div>
            <h1 className="caption caption_size_middle test-page__question">{currentQuestion.question}</h1>
            <div className="test-buttons">
                {
                    currentQuestion.answers.map((answer, index)=>{
                        return (
                            <button
                                className="button button_color_yellow button_size_big"
                                key={index}
                                onClick={()=>toNext(answer.types)}
                            >{answer.title}</button>
                        )
                    })
                }
            </div>
        </div>
    );
}

export default Test;

